<template>
  <div style="background-color: white;">
    <van-nav-bar left-text="返回"
                 right-text="分享此题"
                 fixed
                 placeholder
                 safe-area-inset-top
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight">
      <template #title>
        <span style="font-weight:600;color:#0af">{{ questionType | questionTypeFilter }}</span>
      </template>
    </van-nav-bar>

    <van-cell size="large">

      <!-- 使用 title 插槽来自定义标题 -->
      <template #title>
        <span class="custom-title">{{info.number}}.{{info.questionName}}</span>
      </template>

      <template #label>
        <van-row type="flex"
                 justify="space-around">
          <van-col span="3">
            <van-tag v-if="info.level==1"
                     color="#67c23a"
                     plain>简单</van-tag>
            <van-tag v-else-if="info.level==2"
                     color="#e6a23c"
                     plain>中等</van-tag>
            <van-tag v-else
                     color="#f56c6c"
                     plain>困难</van-tag>

          </van-col>
          <van-col span="7">
            <van-icon name="clock"
                      color="#ff5722" /> {{info.time}}
          </van-col>
          <van-col span="3">
            <van-icon name="fire"
                      color="#ee0a24" /> {{info.frequency}}
          </van-col>
          <van-col span="7">
            <van-rate v-model="info.rate"
                      :count="3"
                      color="#ffd21e"
                      size="12px"
                      @change="rateChange" /> {{ info.rate | rateFilter }}
          </van-col>
          <van-col span="4">

            <div v-if="info.done == 2">
              <van-icon name="checked"
                        color="#1989fa"
                        @click="doneChange" /> 完 成
            </div>
            <div v-else>
              <van-icon name="checked"
                        color="#9e9e9e"
                        @click="doneChange" /> 待 做
            </div>

          </van-col>
        </van-row>
      </template>
    </van-cell>

    <van-button v-if="questionType == 2"
                type="warning"
                size="small"
                :url="info.url"
                block>本题 LeetCode 传送门</van-button>
    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      试题解析
    </van-divider>
    <van-row  v-if="info.videoUrl != null"
             type="flex"
             justify="center" >
      <van-col span="22">
        <iframe :src="info.videoUrl"
        width="100%" height="200" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>

      </van-col>
    </van-row>

    <van-row type="flex"
             justify="center" >
      <van-col span="22">
        <div v-if="info.questionDetail != null"
             class="markdown-body">
          <!-- <vue-markdown :source="info.questionDetail"></vue-markdown> -->

          <viewer ref="markdownViewer"
                    :options="viererOptions"
                    :height="'auto'"
                    :initialValue="info.questionDetail"></viewer>

        </div>
        <div v-else>
          <van-empty description="解析整理中" />
        </div>
      </van-col>
    </van-row>

    <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }">
      参与讨论
    </van-divider>
    <van-row type="flex"
             justify="center" >
      <van-col span="22">
        <Valine :options="valineOptions" />
      </van-col>
    </van-row>
    <van-share-sheet v-model="showShare"
                     title="立即分享"
                     :options="shareOptions"
                     @select="onSelect" />

  </div>
</template>

<script>
import { Button, Dialog, Divider, Collapse, CollapseItem, ShareSheet, Empty, NavBar, Toast, Search, DropdownMenu, DropdownItem, List, Cell, CellGroup, Tag, Icon, Col, Row, Rate, Switch } from 'vant';
import Valine from '../Valine.vue';
const axios = require("axios");

import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-go.js';
// import './style/toastui-editor.css';
import "@toast-ui/editor/dist/i18n/zh-cn";
import './toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';

export default {
  components: {
    viewer: Viewer,    
    [Button.name]: Button,
    [Dialog.Component.name]: Dialog.Component,
    [Divider.name]: Divider,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [ShareSheet.name]: ShareSheet,
    Valine,
    [Empty.name]: Empty,
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Rate.name]: Rate,
    [Switch.name]: Switch
  },

  data () {
    return {      
      viererOptions: {
        plugins: [[codeSyntaxHighlight, { highlighter: Prism }]]
      },
      id: '',
      info: {},
      questionType: '',
      showShare: false,
      shareOptions: [
        { name: '微信', icon: 'wechat' },
        // { name: '微博', icon: 'weibo' },
        // { name: '复制链接', icon: 'link' },
        // { name: '分享海报', icon: 'poster' },
        // { name: '二维码', icon: 'qrcode' },
      ],
      valineOptions: {
        appId: 'kWKlQCNOVzR8JT7EAPJXvanE-gzGzoHsz',
        appKey: 'nscorEe7TaRyn1DBFNicKsWH',
        placeholder: '欢迎大家留言交流，填写邮箱可接收回复信息哦！😎 支持markdown格式，点击右下角预览查看效果。',
        path: window.location.pathname,
      },
      rate: 1,
      done: 1

    };
  },
  filters: {
    questionTypeFilter (val) {
      switch (val) {
        case '1':
          return "GOLANG";
        case '2':
          return "数据结构与算法";
        case '3':
          return "数据库";
        case '4':
          return "操作系统";
        case '5':
          return "网络";
        case '6':
          return "系统设计";
        case '7':
          return "工具";
        case '8':
          return "通用能力";
        default:
          return "11GOLANG";
      }
    },
    rateFilter (val) {
      switch (val) {
        case 1:
          return "了解";
        case 2:
          return "熟悉";
        case 3:
          return "精通";
        default:
          return "未标记";
      }
    },
  },
  created () {
    this.questionType = this.$route.query.questionType
    this.id = this.$route.query.id
    if (this.$route.query.item == 0) {
      this.initData()
    } else {
      this.info = this.$route.query.item
      this.valineOptions.path = '/#/detail?item=0&questionType=' + this.questionType + '&id=' + this.info.id
    }    
  },
  methods: {

    initData () {
      axios
        .get("https://api.golangroadmap.com/question/detail?id=" + this.id)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.info = response.data.data
            this.valineOptions.path = '/#/detail?item=0&questionType=' + this.questionType + '&id=' + this.info.id
            console.log(this.info.url)
          } else {
            console.log(response.data.msg);
            Toast(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    rateChange () {
      //修改状态为完成
      this.info.done = 2
      var params = {
        'id': this.info.id,
        'rate': this.info.rate,
        'questionType': this.questionType,
        'level': this.info.level
      }

      axios
        .post("https://api.golangroadmap.com/question/rateChange", params)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            console.log(response.data.msg);
          } else {
            console.log(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    doneChange () {
      //从已做修改为未做
      if (this.info.done == 2) {
        this.info.rate = 0
        this.info.done = 1
      } else {
        this.info.done = 2
      }
      var params = {
        'id': this.info.id,
        'rate': this.info.rate,
        'questionType': this.questionType,
        'level': this.info.level
      }

      axios
        .post("https://api.golangroadmap.com/question/doneChange", params)
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            console.log(response.data.msg);
          } else {
            console.log(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //分享
    onSelect () {

      Dialog({ message: '本题链接已复制到剪切板，前往微信分享给朋友吧！', theme: 'round-button', confirmButtonColor: '#0af' });
      this.showShare = false;
      let container = this.$refs.container
      var shareUrl = "分享个面试题 : https://m.golangroadmap.com/#/detail?item=0&questionType=" + this.questionType + '&id=' + this.info.id
      this.$copyText(shareUrl, container)

    },
    copyInvitelink () {

      this.$copyText('abc').then(function (e) { console.log(e) }, function (e) {
        alert('Can not copy')
        console.log(e)
      })
    },
    onClickLeft () {



      this.$router.go(-1); // 返回上一页面

    },
    onClickRight () {

      this.showShare = true
    },


  }
};
</script>

<style lang="less">
/* iphone plus 414px */
@media screen and (max-width: 414px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 384px;
  }
}
/* iphone 320px */
@media screen and (max-width: 320px) {
  .van-cell__label {
    max-width: 290px;
  }
}

/* android 360px*/
@media screen and (max-width: 360px) and (min-width: 321px) {
  .van-cell__label {
    max-width: 330px;
  }
}
/* android 360px*/
@media screen and (max-width: 375px) and (min-width: 361px) {
  .van-cell__label {
    max-width: 345px;
  }
}

@media screen and (max-width: 480px) and (min-width: 415px) {
  .van-cell__label {
    max-width: 450px;
  }
}

@media screen and (max-width: 540px) and (min-width: 481px) {
  .van-cell__label {
    max-width: 510px;
  }
}
</style>
